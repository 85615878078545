import { createRouter, createWebHistory } from 'vue-router';

import globalHelper from "@/helpers/global.helper";
import menuItems from "@/constants/sidebar-menu";
import { PageNotFound, AccessDenied } from "../pages/error";
import Login from '../pages/auth/Login.vue';
import ForgotPassword from '../pages/auth/ForgotPassword.vue';
import DashboardIndex from "../pages/index.vue";
import UpdateProfile from "../pages/configuration/users/components/UpdateProfile.vue";
import Users from "../pages/configuration/users/index.vue";
import Roles from "../pages/configuration/roles/index.vue";
import Permissions from "../pages/configuration/permissions/index.vue";
import Devices from "../pages/setting/devices/index.vue";
import Locations from "../pages/setting/locations/index.vue";
import Scans from "../pages/scans/index.vue";
import Quote from "../pages/quote/index.vue";
import Clients from "../pages/setting/clients/index.vue";
import Products from "../pages/setting/products/index.vue";
import Parts from "../pages/setting/parts/index.vue";
import Agents from "../pages/setting/agents/index.vue";
import PanelGroups from "../pages/setting/panel-groups/index.vue";
import Panels from "../pages/setting/panels/index.vue";
import AppSettings from "../pages/setting/app-settings/index.vue";
import EmailTemplates from "../pages/setting/email/template/index.vue";
import EmailHeaderFooter from "../pages/setting/email/header-footer/index.vue";
import CustomerPortal from "../pages/customer-portal/index.vue";
import Dashboard from "../pages/dashboard/index.vue";

const checkAccessURLs = (to) => {
  const userAccessPermission = globalHelper.getCurData('curUserPermissions');
  if (globalHelper.getCurData('curUser') && (userAccessPermission.indexOf(to.meta.access) !== -1 || !to.meta.access)) return true;
  else return false;
};

const routes = [
    {
      path: '/login',
      component: Login,
      beforeEnter: (to, from, next) => {
        if (globalHelper.getCurData('curUser')) {
          next('/');
        } else {
          next();
        }
      }
    }, {
      path: '/forgot-password',
      component: ForgotPassword,
      alias: ['/reset-password'],
      props: route => ({ query: route.fullPath === '/forgot-password' ? null : route.query.token}),
      beforeEnter: (to, from, next) => {
        if (to.path === '/reset-password' && !to.query.token) {
          next('/error');
        } else {
          next();
        }
      }
    }, {
      path: "/error-403", name: "Error 403",
      component: AccessDenied,
    }, {
      path: "/error", name: "Error 404",
      component: PageNotFound,
    }, {
      path: "/home",
      redirect: "/",
    }, {
      path: "/dashboard",
      redirect: "/",
    }, {
      path: "/",
      component: DashboardIndex,
      children: [
        {
          path: '/update-profile', name: 'Update Profile', meta:{ access: 'user'},
          component: UpdateProfile,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
        }, {
          path: '/users', name: 'Users', meta:{ access: 'user'},
          component: Users,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':userId', name: 'Update User', meta:{ access: 'user', indexScreen: 'User' }, component: Users},
            { path: 'create', name: 'Create User', meta:{ access: 'user'}, component: Users}
          ]
        },  {
          path: '/roles', name: 'Roles', meta:{ access: 'role'},
          component: Roles,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':roleId', name: 'Update Role', meta:{ access: 'role', indexScreen: 'Role' }, component: Roles},
            { path: 'create', name: 'Create Role', meta:{ access: 'role'}, component: Roles}
          ]
        },  {
          path: '/permissions', name: 'Permissions', meta:{ access: 'permission'},
          component: Permissions,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':permissionId', name: 'Update Permission', meta:{ access: 'permission', indexScreen: 'Permission' }, component: Permissions},
            { path: 'create', name: 'Create Permission', meta:{ access: 'permission'}, component: Permissions}
          ]
        }, {
          path: '/devices', name: 'Devices', meta:{ access: 'device'},
          component: Devices,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':deviceId', name: 'Update Device', meta:{ access: 'device', indexScreen: 'Device' }, component: Devices},
            { path: 'create', name: 'Create Device', meta:{ access: 'device'}, component: Devices}
          ]
        }, {
          path: '/locations', name: 'Locations', meta:{ access: 'location'},
          component: Locations,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':locationId', name: 'Update Location', meta:{ access: 'location', indexScreen: 'Location' }, component: Locations},
            { path: 'create', name: 'Create Location', meta:{ access: 'location'}, component: Locations}
          ]
        }, {
          path: '/scans', name: 'Scans', meta:{ access: 'scan'},
          component: Scans,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':scanId', name: 'Update Scan', meta:{ access: 'scan', indexScreen: 'Scan' }, component: Scans}
          ],
        }, {
          path: '/quotes', name: 'Quotes', meta:{ access: 'scan'},
          component: Quote,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':quoteScanId', name: 'Quote Generate', meta:{ access: 'scan', indexScreen: 'Scan' }, component: Quote},
            { path: ':quoteScanId/invoice', name: 'Quote Invoice', meta:{ access: 'scan', indexScreen: 'quote' }, component: Quote}
          ],
        },
        {
          path: '/clients', name: 'Clients', meta:{ access: 'client'},
          component: Clients,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':clientId', name: 'Update Client', meta:{ access: 'client', indexScreen: 'Client' }, component: Clients},
            { path: 'create', name: 'Create Client', meta:{ access: 'client'}, component: Clients}
          ]
        }, {
          path: '/products', name: 'Products', meta:{ access: 'product'},
          component: Products,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':productId', name: 'Update Product', meta:{ access: 'product', indexScreen: 'Product' }, component: Products},
            { path: 'create', name: 'Create Product', meta:{ access: 'product'}, component: Products}
          ]
        }, {
          path: '/parts', name: 'Parts', meta:{ access: 'part'},
          component: Parts,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':partId', name: 'Update Part', meta:{ access: 'part', indexScreen: 'Part' }, component: Parts},
            { path: 'create', name: 'Create Part', meta:{ access: 'part'}, component: Parts}
          ]
        }, {
          path: '/agents', name: 'Agents', meta:{ access: 'agent'},
          component: Agents,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':agentId', name: 'Update Agent', meta:{ access: 'agent', indexScreen: 'Agent' }, component: Agents},
            { path: 'create', name: 'Create Agent', meta:{ access: 'agent'}, component: Agents}
          ]
        }, {
          path: '/panelgroups', name: 'Panel Groups', meta:{ access: 'part'},
          component: PanelGroups,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':panelGroupId', name: 'Update Panel Group', meta:{ access: 'panelgroup', indexScreen: 'Panelgroup' }, component: PanelGroups},
            { path: 'create', name: 'Create Panel Group', meta:{ access: 'panelgroup'}, component: PanelGroups}
          ]
        }, {
          path: '/panels', name: 'Panels', meta:{ access: 'part'},
          component: Panels,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':panelId', name: 'Update Panel', meta:{ access: 'panel', indexScreen: 'Panel' }, component: Panels},
            { path: 'create', name: 'Create Panel', meta:{ access: 'panel'}, component: Panels}
          ]
        }, {
          path: '/app-settings', name: 'App Settings', meta:{ access: 'user'},
          component: AppSettings,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':appSettingId', name: 'Edit Setting', meta:{ access: 'user'}, component: AppSettings,}
          ]
        }, {
          path: '/email-templates', name: 'Email Templates', meta:{ access: 'email_template'},
          component: EmailTemplates,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':emailTemplateId', name: 'Update Email Template', meta:{ access: 'email_template', indexScreen: 'Email Templates' }, component: EmailTemplates},
            { path: 'create', name: 'Create Email Template', meta:{ access: 'email_template'}, component: EmailTemplates}
          ]
        }, {
          path: '/email-header-footer', name: 'Email Header & Footer', meta:{ access: 'email_template'},
          component: EmailHeaderFooter,
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
        }, {
            path: '/customer-portal/:agentId', name: 'Customer Portal',
            component: CustomerPortal,
            children: [
                {
                    path: ':scanId', name: 'Updated Scan', meta:{ access: 'scan', indexScreen: 'Scan' },
                    component: CustomerPortal
                },
            ]
        }, {
          path: "/", name: 'Dashboard', meta: { access: false },
          component: Dashboard,
          beforeEnter: (to, from, next) => {
            // const userAccessPermission = globalHelper.getCurData('curUserPermissions'),
            //     route = menuItems.find(item => (item.childAccess && item.childAccess.indexOf(userAccessPermission[0]) !== -1) ||
            //         item.access === userAccessPermission[0]),
            //     navRoute = route.children ? route.children.find(item => (item.childAccess && item.childAccess.includes(userAccessPermission[0])) ||
            //         (item.access && item.access === userAccessPermission[0])) : route;
            if (globalHelper.getCurData('curUser')) {
                // if (checkAccessURLs(to))
                  next();
                // else if (navRoute) next(`/${navRoute.childAccess ? navRoute.children[0].to : navRoute.to}`);
                // else next('/error-403');
            } else {
              next('/login');
            }
          },
        },{
          path: "**", redirect: '/'
        }
      ]
    }
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      return savedPosition || { top: 0 };
    }
  });

export default router;
