<template>
  <v-row class="align-center justify-center" no-gutters>
    <v-col :cols="openPanel ? 12 : 9" class="pa-3">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title v-slot="{ open }" @click="openPanel = !openPanel; menu = false" :cols="8">
            <v-row no-gutters :class="open ? 'px-4' : 'px-2'">
              <!-- Title Column -->
              <v-col
                :cols="open ? 12 : 3"
                class="font-weight-bold"
                :class="openPanel ? 'text-h6' : 'd-flex'">
                {{ openPanel ? 'Advance Filter' : 'Expand to Filter' }}
              </v-col>

              <!-- Information Columns -->
              <v-col
                cols="8"
                class="d-none d-md-flex d-lg-flex d-xl-flex text-secondary"
                v-if="!openPanel">
                <v-row no-gutters class="w-100">
                  <v-col xs="3" sm="4" md="4" lg="4" xl="4" class="text-center">
                    Agent: {{ agentName || 'Not set' }}
                  </v-col>
                  <v-col xs="3" sm="4" md="4" lg="4" xl="4" class="text-center">
                    Client: {{ clientName || 'Not set' }}
                  </v-col>
                  <v-col xs="3" sm="4" md="4" lg="4" xl="4" class="text-center">
                    Location: {{ locationName || 'Not set' }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-form lazy-validation @submit.prevent="$emit('search', model)">
              <v-row class="px-5 pb-0 align-center justify-lg-space-between">
                <v-col cols="12" md="3" sm="6" lg="3" class="d-flex">
                  <v-select
                    density="compact"
                    v-model="model.agent_id"
                    :items="agentsList"
                    item-title="text"
                    item-value="value"
                    variant="outlined"
                    placeholder="---Select Agent---"
                    :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true }"
                    hide-details="auto"
                    clearable
                    @click:clear="$emit('remove', 'agent_id')"
                    @update:modelValue="model.agent_name = agentName"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3" sm="6" lg="3" class="d-flex">
                  <v-select
                    density="compact"
                    v-model="model.client_id"
                    :items="clientsList"
                    item-title="text"
                    item-value="value"
                    variant="outlined"
                    placeholder="---Select Client---"
                    :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true }"
                    hide-details="auto"
                    clearable
                    @click:clear="$emit('remove', 'client_id')"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3" sm="6" lg="3" class="d-flex">
                  <v-select
                    density="compact"
                    v-model="model.location_id"
                    :items="locationsList"
                    item-title="text"
                    item-value="value"
                    variant="outlined"
                    placeholder="---Select Location---"
                    :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true }"
                    hide-details="auto"
                    clearable
                    @click:clear="$emit('remove', 'location_id')"
                    @update:modelValue="model.location_name = locationName"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3" sm="6" lg="3" class="d-flex">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    start
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        hide-details
                        density="compact"
                        placeholder="---Select Date---"
                        style="background-color: white;"
                        variant="outlined"
                        :model-value="formattedDates"
                        label=""
                        readonly
                        clearable
                        @click:clear="clearDates('formattedDates')"
                        v-bind="props"
                      ></v-text-field>
                    </template>
                    <vue-date-picker
                      color="primary"
                      range
                      inline
                      auto-apply
                      no-today
                      hide-offset-dates
                      :enable-time-picker="false"
                      v-model="model.date"
                      @update:model-value="closeDateModal('menu1')"
                    >
                      <template #menu-header>
                        <div class="pa-4 bg-primary">
                          <h1 class="text-h4 text-white">{{ selectedDatesCount }}</h1>
                        </div>
                      </template>
                      <template #calendar-header="{ index, day }">
                        <div :class="index === 5 || index === 6 ? 'red-color' : ''">
                          {{ customHeader[index] }}
                        </div>
                      </template>
                    </vue-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6" lg="3" class="d-flex">
                  <v-btn
                    type="submit"
                    color="primary"
                    class="mr-4 mt-2"
                    :loading="loading"
                    :disabled="loading || !isSearchEnabled"
                  >
                    Search
                  </v-btn>
                  <v-btn
                    type="button"
                    variant="outlined"
                    class="mr-4 mt-2"
                    @click="resetForm"
                    :disabled="loading"
                  >
                    Reset
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col v-if="!openPanel" xs="12" sm="6" md="12" lg="3" xl="3" class="pa-3">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        start
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ props }">
          <v-text-field
            hide-details
            density="compact"
            placeholder="---Select Date---"
            style="background-color: white;"
            variant="outlined"
            :model-value="formattedDates"
            label=""
            readonly
            :clearable="false"
            v-bind="props"
          ></v-text-field>
        </template>
        <vue-date-picker
          color="primary"
          range
          inline
          auto-apply
          no-today
          hide-offset-dates
          :enable-time-picker="false"
          v-model="model.date"
          @update:model-value="onDateUpdate('menu')"
        >
          <template #menu-header>
            <div class="pa-4 bg-primary">
              <h1 class="text-h4 text-white">{{ selectedDatesCount }}</h1>
            </div>
          </template>
          <template #calendar-header="{ index, day }">
            <div>
              {{ customHeader[index] }}
            </div>
          </template>
        </vue-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  props: ['loading'],
  emits: ['search', 'reset', 'remove'],
  data: (vm) => ({
    user: null,
    model: {
      date: [],
      agent_id: null,
      client_id: null,
      location_id: null,
    },
    reseted: false,
    openPanel: false,
    menu: false,
    menu1: false,
    customHeader: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
  }),
  computed: {
    ...mapGetters(['locationsList', 'agentsList', 'clientsList']),

    agentName() {
      const agent = _.find(this.agentsList, item => item.value === this.model.agent_id);
      return this.model.agent_id && agent ? agent.text : null;
    },
    clientName() {
      const client = _.find(this.clientsList, item => item.value === this.model.client_id);
      return this.model.client_id && client ? client.text : null;
    },
    locationName() {
      const location = _.find(this.locationsList, item => item.value === this.model.location_id);
      return this.model.location_id && location ? location.text : null;
    },
    formattedDates() {
      if (this.model.date && this.model.date.length > 0) {
        return this.model.date.map(date => {
          return this.$helpers.getMomentDatas('YYYY-MM-DD', date);
        }).join(',');
      }
      return '';
    },
    selectedDatesCount() {
      const {date} = this.model;
      if (date && Array.isArray(date) && date.length === 2) {
        const daysCount = this.$helpers.getMomentDiff(date[0], 'days', date[1]) + 1;
        return `${daysCount} selected`;
      }
      return '0 selected';
    },
    isSearchEnabled() {
      return !!Object.keys(this.model)
        .map(field => field === 'date'
          ? Array.isArray(this.model[field]) && this.model[field].length === 2
          : this.model[field] ?? false
        )
        .filter(Boolean).length;
    }
  },
  created() {
    this.user = this.$helpers.getCurData('curUser');
    this.getLocationLists();
    this.getAgentLists();
    this.getClientLists();

    let filterParamChanged = false;

    this.model.agent_id = localStorage.getItem('selectedAgent') || null;
    this.model.client_id = localStorage.getItem('selectedClient') || null;

    if (this.model.agent_id === null && this.user.agents.length > 0) {
      this.model.agent_id = this.user.agents[0];
      filterParamChanged = true;
    }

    if (this.model.client_id === null && this.user.clients.length > 0) {
      this.model.client_id = this.user.clients[0];
      filterParamChanged = true;
    }

    if(filterParamChanged) {
      this.$emit('search', this.model);
    }
  },
  methods: {
    ...mapActions(['getLocationLists', 'getAgentLists', 'getClientLists']),

    resetForm() {
      this.reseted = true;
      this.$emit('reset');
      this.model = {
        date: null,
        agent_id: null,
        client_id: null,
        location_id: null,
      },
        setTimeout(() => {
          this.reseted = false;
        });
    },
    closeDateModal(field) {
      if (this.model.date.length === 2) this[field] = false;
    },
    onDateUpdate(field) {
      this.closeDateModal(field);
      this.$emit('search', this.model);
    },
    clearDates(field) {
      this.model.date = [];
      this.$emit('remove', field);
    }
  }
}
</script>
