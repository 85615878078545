<template>
  <v-col cols="12" class="px-0">
    <v-checkbox v-if="!agentId" class="mt-0" v-model="model.manual_check" :true-value="1" :false-value="0"
                @update:modelValue="$emit('modelUpdate', model)"
                color="primary" label="Request quote check"></v-checkbox>
    <v-textarea v-model="model.assessor_comments" hide-details="auto" bg-color="grey-lighten-3"
                @update:modelValue="updateComments"
                variant="outlined" filled auto-grow rows="3" placeholder="Add Comments"></v-textarea>
  </v-col>
</template>

<script>
export default {
  props: ['data'],
  emits: ['modelUpdate'],
  data: (vm) => ({
    model: {
      manual_check: 0,
      assessor_comments: ''
    },
    initalLoading: false,
    agentId: vm.$route.params.agentId || null,
  }),
  watch: {
    data(newValue) {
      if (newValue && !this.initalLoading) {
        this.model = { ...this.model, ...newValue };
        this.initalLoading = true;
      }
    },
    $route(newVal) {
      this.agentId = newVal.params.agentId || null;
    }
  },
  methods: {
    updateComments() {
      // Emit the updated model object
      this.$emit('modelUpdate', this.model);
    },
  },
  created() {
    if (this.data) {
      this.model = { ...this.model, ...this.data };
      this.initalLoading = true;
    }
  }
}
</script>

<style>

</style>
