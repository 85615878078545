<template>
    <div>
        <v-row class="ma-0 mb-lg-6 mb-xl-6 align-center justify-center">
            <v-img v-if="model.logo" :src="model.logo" height="160" @error="model.logo = null">
                <template v-slot:placeholder>
                <v-row class="ma-0 w-100 h-100 align-center justify-center" style="height: 160px">
                    <div class="spinner-design-loader"></div>
                </v-row>
                </template>
            </v-img>
            <v-row v-else class="ma-0 w-100 h-100 align-center justify-center">
                <h2>{{ model.name }}</h2>
            </v-row>
        </v-row>
        <v-divider class="black"/>
        <v-row class="ma-0 justify-sm-end">
            <v-col cols="12" md="4" xl="4" lg="4" class="text-lg-h6 text-xl-h6 text-md-h6 font-weight-bold align-center">
                <span class="mr-2">Phone:</span>{{ model.phone.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3') || '-' }}
            </v-col>
            <v-col cols="12" md="4" xl="4" lg="4" class="text-lg-h6 text-xl-h6 text-md-h6  font-weight-bold align-center">
                <div class="text-capitalize" v-if="model.address">{{ model.address}}</div>
                <div v-if="model.email">{{ model.email}}</div>
            </v-col>
            <v-col cols="12" md="4" xl="4" lg="4" class="text-lg-h6 text-xl-h6 text-md-h6 font-weight-bold align-center">
                <span class="mr-2">
                    {{ isJapaneseLocale ? "法人番号" : "ABN" }} :
                </span>{{ model.abn.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3') || '-' }}
            </v-col>
        </v-row>
        <v-divider class="black mb-5"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: ['model'],
    computed: {
        ...mapGetters(["isJapaneseLocale"]),
    }
}
</script>