export default [
    {
        title: "Dashboard",
        to: "dashboard",
        route: 'Dashboard',
        access: false,
        icon: "mdi-view-dashboard",
    }, {
        title: "Scans",
        to: "scans",
        route: ['Scans', 'Update Scan'],
        access: 'scan',
        icon: "mdi-car-select",
    }, {
        title: "Quotes",
        to: "quotes",
        route: ['Quotes', 'Create Quote', 'Update Quote'],
        access: 'scan',
        icon: "mdi-receipt-text",
    }, {
        title: "Configuration",
        icon: "mdi-table-cog",
        group: "configuration",
        childAccess: ['user', 'role', 'permission'],
        children: [
            {
                title: "Users",
                to: "users",
                route: ['Users', 'Create User', 'Update User'],
                access: 'user',
                icon: "mdi-account-network",
            }, {
                title: "Roles",
                to: "roles",
                route: ['Roles', 'Create Role', 'Update Role'],
                access: 'role',
                icon: "mdi-account-cog",
            }, {
                title: "Permissions",
                to: "permissions",
                access: 'permission',
                route: ['Permissions', 'Create Permission', 'Update Permission'],
                icon: "mdi-script-text-key",
            }
        ]
    }, {
        title: "Setting",
        icon: "mdi-cogs",
        group: "setting",
        childAccess: ['device', 'location', 'client', 'agent', 'product', 'panelgroup', 'panel', 'email_template', 'app_setting'],
        children: [
            {
                title: "Devices",
                to: "devices",
                route: ['Devices', 'Create Device', 'Update Device'],
                access: 'device',
                icon: "mdi-devices",
            }, {
                title: "Locations",
                to: "locations",
                route: ['Locations', 'Create Location', 'Update Location'],
                access: 'location',
                icon: "mdi-map-marker-radius",
            }, {
                title: "Clients",
                to: "clients",
                route: ['Clients', 'Create Client', 'Update Client'],
                access: 'client',
                icon: "mdi-handshake",
            }, {
                title: "Agents",
                to: "agents",
                route: ['Agents', 'Create Agent', 'Update Agent'],
                access: 'agent',
                icon: "mdi-face-agent",
            }, {
                title: "Products",
                to: "products",
                route: ['Products', 'Create Product', 'Update Product'],
                access: 'product',
                icon: "mdi-package-variant",
            }, {
                title: "Parts",
                to: "parts",
                route: ['Parts', 'Create Part', 'Update Part'],
                access: 'part',
                icon: "mdi-car-wrench",
            }, {
                title: "Panel Groups",
                to: "panelgroups",
                route: ['Panel Groups', 'Create Panel Group', 'Update Panel Group'],
                access: 'panelgroup',
                icon: "mdi-format-list-group",
            }, {
                title: "Panels",
                to: "panels",
                route: ['Panels', 'Create Panel', 'Update Panel'],
                access: 'panel',
                icon: "mdi-post-outline",
            }, {
                title: "Application Settings",
                to: "app-settings",
                route: ['App Settings', 'Edit Setting'],
                access: 'user',
                icon: "mdi-application-cog-outline",
            }, {
                title: "Email Template",
                icon: "mdi-email-outline",
                group: "email",
                childAccess: ['email_template'],
                children: [
                    {
                        title: "Header & Footer",
                        to: "email-header-footer",
                        route: 'Email Header & Footer',
                        access: 'email_template',
                        icon: "mdi-page-layout-header-footer",
                    }, {
                        title: "Default Templates",
                        to: "email-templates",
                        route: ['Email Templates', 'Create Template', 'Update Template'],
                        access: 'email_template',
                        icon: "mdi-page-layout-body",
                    }
                ]
            }
        ]
    }
];
