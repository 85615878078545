import http from '@/http';

class panelgroups {
  panelGroupsList(data) {
    return http('quote').get('panelGroups/lists', data);
  }

  panelGroupPositions(data) {
    return http('quote').get('panelGroups/positions', data);
  }

  panelGroupModel(panelGroupId) {
    return http('quote').get(`panelGroups/${panelGroupId}`);
  }

  createPanelGroupModel(data) {
    return http('quote').post('panelGroups', data);
  }

  updatePanelGroupModel(id, data) {
    return http('quote').post(`panelGroups/${id}?_method=PUT`, data);
  }

  deletePanelGroupModel(panelGroupId) {
    return http('quote').deleteReq(`panelGroups/${panelGroupId}`)
  }
}

export default new panelgroups();
