<template>
  <div>
    <v-sheet class="header-backup"></v-sheet>
    <v-app-bar :color="$vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor"
               :theme="getThemeMode.appBarColor != 'white' ? 'dark' : 'light'" class="px-sm text-left shadow-sm pr-0" flat
               height="60"
    >
      <v-app-bar-nav-icon
        v-if="!getHeaderIconHide && !$router.currentRoute.value.fullPath.includes('/customer-portal/')"
        v-ripple="{ class: color ? color : 'text-primary' }" @click="toggleVerticalSidebarDrawer"
      />
      <!-- <v-img v-if="(!$router.currentRoute.value.fullPath.includes('/customer-portal/')) || ($router.currentRoute.value.fullPath.includes('/customer-portal/') && selectedAgent.logo)"
            :src="!$router.currentRoute.value.fullPath.includes('/customer-portal/') ? portalLogo : selectedAgent.logo"
            class="flex-grow-0 d-none d-lg-block d-xl-block d-md-block d-sm-block" width="165" height="31"
            :class="$router.currentRoute.value.name == 'Quote Invoice' ? 'cursor-pointer' : ''"
            @click="$router.currentRoute.value.name == 'Quote Invoice' && goScanPage()"/> -->
      <v-progress-linear
        :active="getThemeMode.isLoading"
        :indeterminate="getThemeMode.isLoading"
        absolute
        bottom
        :color="primary ? primary : 'primary'"
      />
      <v-spacer/>
      <v-container v-if="!$router.currentRoute.value.fullPath.includes('/customer-portal/')" style="max-width: 900px;">
        <v-row justify="end">
          <v-col cols="12" md="3">
            <v-select density="compact" :items="agentOptions" item-text="title" item-value="value" item-group="header"
                      placeholder="Select Agent" variant="outlined" required hide-details="auto" v-model="selectedAgent"
                      @update:modelValue="value => handleSelection('agent', value)"/>
          </v-col>
          <v-col cols="12" md="3">
            <v-select density="compact" :items="clientOptions" item-text="title" item-value="value" item-group="header"
                      placeholder="Select Client" variant="outlined" required hide-details="auto" v-model="selectedClient"
                      @update:modelValue="value => handleSelection('client', value)"/>
          </v-col>
        </v-row>
      </v-container>
      <google-translate-select :default-language-code="defaultLanguageCode" :languages="availableLanguages" :fetch-browser-language="false" trigger="click" @select="handleGoogleTranslateSelect"/>
      <v-chip v-if="!$router.currentRoute.value.fullPath.includes('/customer-portal/')" pill
              class="transparent rounded-pill py-6 text-capitalize" @click="userDrawer = !userDrawer">
        <v-avatar class="mr-2">
          <v-img :src="Profile"></v-img>
        </v-avatar>
        {{ user && user.name ? user.name : 'User' }}
      </v-chip>
    </v-app-bar>
    <!-- userDrawer -->
    <v-navigation-drawer v-model="userDrawer" fixed location="right" temporary floating width="350"
                         class="profile-drawer">
      <user-drawer>
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="userDrawer = !userDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <v-btn block v-if="isImpersonated" class="shadow-sm mb-2" :class="color ? color : ' text-primary'" @click="leaveImpersonation"
                 :style="{ backgroundColor: '#888cb7' }">
            <v-icon class="transition-none">mdi-logout</v-icon>
            Leave Impersonate
          </v-btn>
          <v-btn block class="shadow-sm" :class="color ? color : ' text-primary'" @click="logoutUser"
                 :style="{ backgroundColor: '#888cb7' }">
            <v-icon class="transition-none">mdi-logout</v-icon>
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

import Profile from "@/assets/images/1.png";
import portalLogo from '@/assets/images/sngword.png';
import UserDrawer from "../../layouts/common-drawer/UserDrawer.vue";
import globalHelper from "@/helpers/global.helper";
import auth from "@/store/modules/auth/api";

export default {
  name: "HeaderLayout",
  components: {
    UserDrawer,
  },
  computed: {
    ...mapGetters(["getThemeMode", 'getHeaderIconHide', 'primary', 'color']),

    userRoles() {
      const currentUser = JSON.parse(localStorage.getItem('SCAN.CURRENT_USER'));
      return currentUser ? currentUser.roles : [];
    },

    isImpersonated() {
      return !!localStorage.getItem('impersonatedUser');
    },
  },
  data() {
    return {
      defaultLanguageCode: "en",
      availableLanguages: [
        {
          code: "en",
          name: "English",
        },
        {
          code: "ja",
          name: "Japanese",
        },
      ],
      user: null,
      userDrawer: false,
      searchDrawer: false,
      Profile,
      portalLogo,
      agentOptions: [],
      clientOptions: [],
      allSelected: false,
      selectedOptions: [],
      selectedAgent: '',
      selectedClient: '',
    };
  },
  created() {
    this.user = this.$helpers.getCurData('curUser');
    this.fetchDataOnLoad();
  },
  methods: {
    ...mapActions(["changeVerticalSidebarDrawer", "changeHideHeaderIcon",
      "changeVerticalSidebarMini", "signOut", 'updateVerticalSidebarDrawer', 'getAgentLists', 'getClientLists', 'getSelectedAgent', 'getScanModel', 'resetToDefault']),

    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer();
    },
    logoutUser() {
      if (localStorage.getItem('impersonatedUser')) {
        localStorage.removeItem('impersonatedUser');
      }
      this.$helpers.logout();
      this.$router.push("/login");
    },
    goScanPage() {
      this.changeHideHeaderIcon(false);
      this.updateVerticalSidebarDrawer(true);
      this.$router.push('/scans');
    },
    handleGoogleTranslateSelect(language) {
      this.$store.dispatch('updateLocale', language.code);
    },
    async fetchDataOnLoad() {
      await Promise.all([this.fetchData('agent'), this.fetchData('client')]);

      this.selectedAgent = localStorage.getItem('selectedAgent') || 'all';
      this.selectedClient = localStorage.getItem('selectedClient') || 'all';
      const storedAgentDetails = localStorage.getItem('selectedAgentDetails');

      if (this.selectedAgent === 'all' && this.user.agents.length > 0) {
        this.selectedAgent = this.user.agents[0];
      }

      if (this.selectedClient === 'all' && this.user.clients.length > 0) {
        this.selectedClient = this.user.clients[0];
      }

      if (storedAgentDetails) {
        const {primaryColor, textColor} = JSON.parse(storedAgentDetails);
        this.updateTheme(primaryColor, textColor);
      }

      await this.handleSelection('agent', this.selectedAgent);
      await this.handleSelection('client', this.selectedClient);
    },
    async fetchData(type) {
      const fetchMethods = {
        agent: this.getAgentLists,
        client: this.getClientLists,
      };

      const optionsKey = type === 'agent' ? 'agentOptions' : 'clientOptions';
      const fetchData = fetchMethods[type];

      try {
        const response = await fetchData();
        this[optionsKey] = [
          {title: "All", value: "all"},
          ...response.map(item => ({title: item.text, value: item.value})),
        ];
      } catch (error) {
        console.error(`Error fetching ${type} lists:`, error);
      }
    },

    async handleSelection(type, selectedValue) {
      const isAgent = type === "agent";
      const options = isAgent ? this.agentOptions : this.clientOptions;
      const storageKey = isAgent ? "selectedAgent" : "selectedClient";
      const allSelected = selectedValue === "all";

      this.allSelected = allSelected;

      if (allSelected) {
        this.selectedOptions = options
          .filter((option) => option.value !== "all")
          .map((option) => option.value);

        localStorage.removeItem(storageKey);
        if (isAgent) {
          localStorage.removeItem("selectedAgentDetails");
        }

        // Fallback to default colors
        const primaryColor = "#0C1839";
        const textColor = "";
        this.updateTheme(primaryColor, textColor);
        this.resetToDefault();
      } else {
        this.selectedOptions = selectedValue;
        if (Array.isArray(this.selectedOptions)) {
          localStorage.setItem(storageKey, JSON.stringify(this.selectedOptions));
        } else {
          localStorage.setItem(storageKey, this.selectedOptions);
        }

        if (isAgent) {
          await this.updateAgentDetails(selectedValue);
        }
      }
      this.$router.push("/dashboard");
      await this.callScanModel();
    },

    async updateAgentDetails(selectedValue) {
      try {
        const agentDetails = await this.getSelectedAgent(selectedValue);
        const primaryColor = agentDetails.primary_color || "#0C1839";
        const textColor = agentDetails.text_color || "";

        // Store the selected agent's details in local storage
        const agentData = {
          id: agentDetails.id,
          name: agentDetails.name,
          primaryColor: primaryColor,
          textColor: textColor,
        };
        localStorage.setItem("selectedAgentDetails", JSON.stringify(agentData));

        // Update Vuetify theme dynamically
        this.updateTheme(primaryColor, textColor);

        // Dispatch to update logo if needed
        this.$store.dispatch("updateLogo", agentDetails.logo);
      } catch (error) {
        console.error("Error fetching selected agent details:", error);
      }
    },

    updateTheme(primaryColor, textColor) {
      // Dynamically update Vuetify theme colors
      this.$vuetify.theme.themes.light.colors.primary = primaryColor;
      this.$vuetify.theme.themes.light.colors["on-primary"] = textColor;

      // Update the global text-primary color to match on-primary (textColor)
      document.documentElement.style.setProperty("--v-theme-primary-text", textColor);

      // Update the Vuex store
      this.$store.dispatch("updatePrimaryColor", primaryColor);
      this.$store.dispatch("updateTextColor", textColor);
    },

    async callScanModel() {
      try {
        await this.getScanModel({endPoint: 'agent-statistics', params: {}});
      } catch (error) {
        console.error("Error calling getScanModel:", error);
      }
    },

    async leaveImpersonation() {
      return new Promise((res, rej) => {
        auth.leaveImpersonation(localStorage.getItem("impersonatedUser")).then(response => {
          globalHelper.lsPush("curUser", response);
          localStorage.removeItem("impersonatedUser");
          this.$router.push("/")
            .then(() => {
              window.location.reload();

              this.$store.commit("snackbar/SHOW_MESSAGE", {
                text: "Leave from impersonation Successfully",
                color: "success",
              });
            });
          res(response);
        }).catch((error) => {
          rej(error)
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-backup {
  display: block;
  width: 100%;
  height: 'auto';
  position: fixed;
  top: 0;
  z-index: 5;
  background: linear-gradient(
      180deg,
      hsla(0, 0%, 97.3%, 0.95) 44%,
      hsla(0, 0%, 97.3%, 0.46) 73%,
      hsla(0, 0%, 100%, 0)
  ) !important;
  left: 0;

  &.theme--dark {
    background: linear-gradient(
        180deg,
        rgba(5, 5, 5, 0.95) 44%,
        rgba(0, 0, 0, 0.46) 3%,
        hsla(0, 0%, 100%, 0)
    ) !important;
  }
}

.profile-drawer {
  top: 0px !important;
  height: 100% !important;
  z-index: 1300 !important;
  position: fixed !important;
}

.tippy-box[data-theme~="light"] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.vnb {
  background: transparent !important;

  &__menu-options {
    margin-top: 3px !important;

    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }

        &:hover {
          color: #0081ff;

          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }

        color: #000 !important;

        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }

  &:after {
    content: "\F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }

  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;

  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }

    top: 20px;
    right: -33px;

    svg {
      fill: #000 !important;
    }
  }
}
</style>
