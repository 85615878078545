<template>
  <div id="dashboard-page" class="w-full">
    <div class="d-flex align-center justify-space-between">
      <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
            :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <AdvanceFilter v-if="hasDashboardStatistics" @search="searchFilter" @reset="filterData = null; getFilterAPI()" :loading="loading"
      @remove="searchFilter($event, 'remove')" />

    <common-modal :open="openValidation" :width="'70%'" :persistent="true">
      <v-card class="widh-full">
        <v-card-title class="text-primary">{{ termsTitle }}</v-card-title>
        <v-card-text>
          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <div v-html="termsContent"></div>
            </v-col>
            <v-col cols="12">
              <!-- Terms and Conditions Checkbox -->
              <v-checkbox
                  v-model="termsAccepted"
                  label="I agree to the Terms and Conditions"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div class="d-block d-lg-flex justify-end mt-4">
            <v-btn type="submit" @click="submitTerms" color="primary" :disabled="!termsAccepted" :loading="loading" class="mr-4">
                Submit
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </common-modal>

    <h5 class="text-center mt-10"> {{ scanDate }} Scans</h5>
    <v-row class="w-full h-full mt-10 align-center justify-center" v-if="hasDashboardStatistics">
      <v-col cols="12" xl="6" lg="6" class="py-0">
        <h6 class="text-center mt-6">Agent's</h6>
        <v-row class="ma-0 w-100 align-center justify-center" v-if="this.model.length == 0 || loading" style="height: 300px">
          <h6 class="text-black" v-if="!loading">Data Not Available!.<v-icon color="warning" class="mb-2 ml-2">mdi-alert</v-icon></h6>
          <div class="spinner-design-loader" v-else></div>
        </v-row>
        <v-card v-show="!loading && model.length > 0" class="pa-0 ma-0">
          <div ref="chartdiv" class="w-100" style="height: 500px"></div>
        </v-card>
      </v-col>
      <v-divider class="d-flex d-xl-none d-lg-none d-my-7" />
      <v-col cols="12" xl="6" lg="6" class="py-0">
        <h6 class="text-center mt-6">Location's</h6>
        <v-row class="ma-0 w-100 align-center justify-center" v-if="this.locationModel.length == 0 || locationLoading" style="height: 300px">
          <h6 class="text-black" v-if="!locationLoading">Data Not Available!.<v-icon color="warning" class="mb-2 ml-2">mdi-alert</v-icon></h6>
          <div class="spinner-design-loader" v-else></div>
        </v-row>
        <v-card v-else class="pa-0 ma-0">
          <div ref="locationChartdiv" class="w-100" style="height: 500px"></div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { mapActions } from 'vuex';

import AdvanceFilter from "./components/AdvanceFilter.vue";
import {CommonModal} from "@/components/common";

export default {
  components: {
    CommonModal,
    AdvanceFilter,
  },
  data: (vm) => ({
    breadcrumbList: [{
      text: 'Scan N Go Analytics',
      disabled: true,
      href: '/',
    }],
    chart: '',
    locationChart: '',
    model: [],
    locationModel: [],
    loading: false,
    locationLoading: false,
    filterData: null,
    termsContent: "",
    termsTitle: "",
    termsAccepted: false,
    openValidation: false,
    permissions: []
  }),
  computed: {
    scanDate() {
      const currDate = this.$helpers.getMomentDatas('YYYY-MM-DD');
      if (!this.filterData || (this.filterData && this.filterData.length && this.filterData.every(item => item && item === currDate))) {
        return `Today's`;
      } else {
        const startDate = this.$helpers.getMomentDatas('YYYY-MM-DD', this.filterData.date[0]);
        const endDate = this.filterData.date[1] ? this.$helpers.getMomentDatas('YYYY-MM-DD', this.filterData.date[1]) : startDate;
        return `${startDate} - ${endDate}`;
      }
    },
    hasDashboardStatistics() {
      return this.permissions.includes('dashboard_statistics');
    }
  },
  created() {
    this.getAgentModel();
    this.getLocationModel()
    this.getOptions()
    this.user = this.$helpers.getCurData('curUser');
    this.permissions = this.user.permissions;
    this.getSelectedUser(this.user.id).then(response => {
      this.openValidation = response.consent === 0 ? true : false;
    }).catch(error => {
      console.error("Error fetching user consent status:", error);
    });
  },
  mounted() {
    if (!this.loading && this.model.length) this.chartInitiated();
    if (!this.locationLoading && this.locationModel.length) this.locationChartInitiated();
  },
  methods: {
    ...mapActions(['getScanModel', 'getUserOptions', 'getOption', 'updateSelectedUser', 'getSelectedUser']),


    searchFilter(data, type) {
      if (type) {
        if (this.filterData && this.filterData[data]) {
          this.filterData[data] = null;
          if (data === 'agent_id' || data === 'location_id') this.filterData[data.split('_')[0]] = null;
        }
        return;
      }
      this.filterData = data;
      this.getFilterAPI();
    },
    async getFilterAPI() {
      let params = {};

      const roles = this.user.roles.map(role => role.toLowerCase());
      const { permissions, id } = this.user;

      if (!roles.includes('super admin')) {
        const roleMap = { agent: 'agent_id', client: 'client_id' };

        Object.entries(roleMap).forEach(([key, paramKey]) => {
          if (roles.includes(key) || permissions.includes(key)) {
            params[paramKey] = id;
          }
        });
      }

      console.log(`getFilterAPI> params`, params)

      if (this.filterData) {
        const keyFilter = _.filter(Object.keys(this.filterData), key => this.filterData[key]);
        await _.map(keyFilter, key => {
          if (key === 'date') {
            params.ts = this.filterData[key][0];
            params.td = this.filterData[key][1];
          } else params[key] = this.filterData[key];
        });
      }
      this.getAgentModel(params);
      this.getLocationModel(params);
    },
    async getOptions() {
      try {
        const response = await this.getUserOptions();
        const options = response.data;
        const termsOption = options.find(option => option.option_name === 'TERMS_AND_CONDITIONS');
        if (termsOption) {
          this.getOptionModel({ id: termsOption.id });
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    },
    async getOptionModel(params = {}) {
      try {
        const response = await this.getOption(params);
        this.termsTitle = response.option_name.replace(/_/g, ' ');
        this.termsContent = response.option_value;
      } catch (error) {
        console.error("Error fetching option model:", error);
      }
    },
    async submitTerms() {
      if (this.termsAccepted) {
        const params = {
          id: this.user.id,
          consent: true,
        };
        try {
          const response = await this.updateSelectedUser(params);
          this.termsAccepted = response.consent;
          this.openValidation = false;
        } catch (error) {
          console.error("Error updating user consent:", error);
        }
      }
    },
    getAgentModel(params = {}) {
      this.loading = true;
      this.getScanModel({ endPoint: 'agent-statistics', params }).then(resp => {
        this.model = resp || [];
        this.loading = false;
        setTimeout(() => {
          this.chartInitiated();
        });
      }).catch(err => this.loading = false);
    },
    getLocationModel(params = {}) {
      this.locationLoading = true;
      this.getScanModel({ endPoint: 'location-statistics', params }).then(resp => {
        this.locationModel = resp || [];
        this.locationLoading = false;
        setTimeout(() => {
          this.locationChartInitiated();
        });
      }).catch(err => this.locationLoading = false);
    },
    async chartInitiated() {
      if (this.permissions.includes('dashboard_statistics')) {
        try{
          if (!this.$refs.chartdiv || this.model.length === 0) {
            console.warn('Chart div reference is not available');
            return;
          }
          // Create chart instance
          let root = am5.Root.new(this.$refs.chartdiv);

          //Remove Amchart Logo From Leftside Bottom
          root._logo.dispose();

          // Themes begin
          root.setThemes([am5themes_Animated.new(root)]);

          // Create container
          let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
          }));

          // Create axes
          const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "agent_name",
            renderer: am5xy.AxisRendererY.new(root, {
              inversed: true,
              cellStartLocation: 0.1,
              cellEndLocation: 0.9
            })
          }));
          yAxis.data.setAll(this.model);

          const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {}),
            min: 0
          }));
          const chartData = {
            axes: { xAxis: xAxis, yAxis: yAxis },
            chart: chart,
            data: this.model
          }

          // Add cursor
          const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomY"
          }));
          cursor.lineY.set("forceHidden", true);
          cursor.lineX.set("forceHidden", true);

          // Add series
          await this.createSeries("arch", root, chartData, 'agent_name');
          await this.createSeries("chasis", root, chartData, 'agent_name');
          await this.createSeries("tire", root, chartData, 'agent_name');

          // Add legend
          if (this.model.length) {
            const legend = chart.children.push(am5.Legend.new(root, {
              centerX: am5.p50,
              x: am5.p50,
              marginTop: 40
            }))
            legend.data.setAll(chart.series.values);
          }

          // Make stuff animate on load
          chart.appear(1000, 100);

          this.chart = chart;
        } catch (error) {
            console.error('Error initializing chart:', error);
        }
      }
    },
    async locationChartInitiated() {
      if (this.permissions.includes('dashboard_statistics')) {
        // Add a null check for the ref
        if (!this.$refs.locationChartdiv || this.locationModel.length === 0) {
          console.warn('Location chart div reference is not available');
          return;
        }
        // Create chart instance
        let root = am5.Root.new(this.$refs.locationChartdiv);

        //Remove Amchart Logo From Leftside Bottom
        root._logo.dispose();

        // Themes begin
        root.setThemes([am5themes_Animated.new(root)]);

        // Create container
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.verticalLayout
        }));

        // Create axes
        const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
          categoryField: "location_name",
          renderer: am5xy.AxisRendererY.new(root, {
            inversed: true,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9
          })
        }));
        yAxis.data.setAll(this.locationModel);

        const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          min: 0
        }));
        const chartData = {
          axes: {xAxis: xAxis, yAxis: yAxis},
          chart: chart,
          data: this.locationModel
        }

        // Add cursor
        const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
          behavior: "zoomY"
        }));
        cursor.lineY.set("forceHidden", true);
        cursor.lineX.set("forceHidden", true);

        // Add series
        await this.createSeries("arch", root, chartData, 'location_name');
        await this.createSeries("chasis", root, chartData, 'location_name');
        await this.createSeries("tire", root, chartData, 'location_name');

        // Add legend
        if (this.locationModel.length) {
          const legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
            marginTop: 40
          }))
          legend.data.setAll(chart.series.values);
        }

        // Make stuff animate on load
        chart.appear(1000, 100);

        this.locationChart = chart;
      }
    },
    createSeries(field, root, chartData, categoryfield = null) {
      let series = chartData.chart.series.push(am5xy.ColumnSeries.new(root, {
        name: _.startCase(_.toLower(field)),
        valueXField: field,
        categoryYField: categoryfield,
        sequencedInterpolation: true,
        ...chartData.axes,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "[bold]{categoryY}[/]\n{name}: {valueX}"
        })
      }));

      series.columns.template.setAll({
        height: am5.p100
      });

      series.bullets.push(() => am5.Bullet.new(root, {
        locationX: 1,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          centerY: am5.p50,
          text: "{valueX}",
          populateText: true
        })
      }));
      series.bullets.push(() => am5.Bullet.new(root, {
        locationX: 1,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          centerX: am5.p100,
          centerY: am5.p50,
          text: "{name}",
          fill: am5.color(0xffffff),
          populateText: true
        })
      }));

      series.data.setAll(chartData.data);
      series.appear();

      return series;
    }
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
    if (this.locationChart) {
      this.locationChart.dispose();
    }
  }
};
</script>
