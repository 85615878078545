import http from '@/http';

class agents {
  agentsList(data) {
    return http('agent').get('agents/lists', data);
  }

  agentModel(agentId) {
    return http('agent').get(`agents/${agentId}`);
  }

  validatePincode(id, data) {
    return http('agent').post(`agents/${id}/validate-pin`, data);
  }

  createAgentModel(data) {
    return http('agent').post('agents', data);
  }

  updateAgentModel(id, data) {
    return http('agent').post(`agents/${id}?_method=PUT`, data);
  }

  deleteAgentModel(agentId) {
    return http('agent').deleteReq(`agents/${agentId}`)
  }
}

export default new agents();
