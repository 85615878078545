<template>
    <v-row class="ma-0"  justify="center">
        <v-col cols="6" lg="6" md="6">
            <v-row class="ma-0"  justify="end">
                <v-col cols="6" lg="3" xl="3">{{ isJapaneseLocale ? "登録番号" : "Registration" }} :</v-col>
                <v-col cols="6" lg="5" xl="5">
                    {{ model && model.licensePlate ? model.licensePlate : '-' }}
                </v-col>
            </v-row>
            <v-row class="ma-0"  justify="end">
                <v-col cols="6" lg="3" xl="3">{{ isJapaneseLocale ? "メーカー" : "Make" }}:</v-col>
                <v-col cols="6" lg="5" xl="5">
                    {{ model && model.make ? model.make : '-'}}
                </v-col>
            </v-row>
            <v-row class="ma-0"  justify="end">
                <v-col cols="6" lg="3" xl="3">{{ isJapaneseLocale ? "車種" : "Model" }}:</v-col>
                <v-col cols="6" lg="5" xl="5">
                    {{ model && model.model ? model.model : '-'}}
                </v-col>
            </v-row>
            <v-row class="ma-0"  justify="end">
                <v-col cols="6" lg="3" xl="3">{{ isJapaneseLocale ? "車体色の系統" : "Colour" }}:</v-col>
                <v-col cols="6" lg="5" xl="5" class="text-capitalize">
                    {{ model && model.carColor ? model.carColor : '-'}}
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="6" lg="6" md="6">
            <v-row class="ma-0"  justify="start">
                <v-col cols="6" lg="4" xl="4">{{ isJapaneseLocale ? "スキャン日時" : "Scan Date/Time" }}:</v-col>
                <v-col cols="6">
                    {{ model && model.timestamp ? (isJapaneseLocale ? $helpers.getMomentDatas('YYYY-MM-DD A hh:mm', model.timestamp) : $helpers.getMomentDatas('DD-MM-YYYY hh:mm A', model.timestamp)) : '-' }}
                </v-col>
            </v-row>
            <v-row class="ma-0"  justify="start">
                <v-col cols="6" lg="4" xl="4">{{ isJapaneseLocale ? "評価者" : "Assessor" }}: [logged in user]:</v-col>
                <v-col cols="6">{{ assessor ? assessor : '-'}}</v-col>
            </v-row>
            <v-row class="ma-0"  justify="start">
                <v-col cols="6" lg="4" xl="4">{{ isJapaneseLocale ? "見積作成元" : "Agent" }}:</v-col>
                <v-col cols="6">{{ model && model.agent_name ? model.agent_name : '-' }}</v-col>
            </v-row>
            <v-row class="ma-0"  justify="start">
                <v-col cols="6" lg="4" xl="4">{{ isJapaneseLocale ? "ご依頼元" : "Client" }}:</v-col>
                <v-col cols="6">{{ model && model.client_name ? model.client_name : '-' }}</v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: ['model', 'assessor'],
    computed: {
        ...mapGetters(["isJapaneseLocale"]),
    }
}
</script>

<style>

</style>