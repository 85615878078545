<template>
  <div id="scan-index-page">
    <AdvanceFilter @search="searchFilter" @reset="filterData = null;getDataFromApi('refresh')" :loading="loading"
      @remove="searchFilter($event, 'remove')"/>
    <v-card>
      <v-row class="ma-0 pa-3 pb-5 align-center justify-end">
        <v-col cols="7" class="d-flex d-xs d-sm pa-0">
          <v-btn color="primary" @click="exportScanData" :loading="exportLoading">
            Export
          </v-btn>
        </v-col>
        <v-col cols="5" md="5" lg="5" xl="5" class="pa-3">
          <div class="w-full">
            <v-text-field v-model.trim="search" append-inner-icon="mdi-magnify" class="pt-0"
              label="" placeholder="Search Scan" :disabled="loading" hide-details
              @update:model-value="searchDatatable" variant="underlined">
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-data-table-server
        :headers="headers"
        :items="tableItems"
        :items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        hover
        @update:options="options = $event"
      >
        <template v-slot:[`item.date`]="{item, column}">
          {{ $helpers.getMomentDatas(column.format, item[column.field]) }}
        </template>
        <template v-slot:[`item.action`]="{item}">
          <v-icon size="small" class="mr-2 cursor-pointer" color="primary" @click="$router.push(`scans/${item.id}`)">
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table-server>
    </v-card>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import _ from 'lodash';

import AdvanceFilter from "./components/AdvanceFilter.vue";

export default {
  components: {
    AdvanceFilter,
  },
  data() {
    return {
      totalItems: 0,
      tableItems: [],
      search: '',
      filterData: null,
      loading: true,
      options: {},
      headers: [
        {title: 'Location', key: 'location_name'},
        {title: 'Type', key: 'type'},
        {title: "Date/Time", key: "date", field: 'timestamp', format: 'DD-MM-YYYY hh:mm A', sortable: false},
        {title: 'Registration', key: 'licensePlate'},
        {title: 'Car Color', key: 'carColor'},
        {title: "Client", key: "client_name"},
        {title: 'Agent', key: 'agent_name'},
        // { text: "Damage Count", value: "damage_count" },
        {title: "Status", key: "status"},
        {title: 'Action', key: 'action', sortable: false}
      ],
      pageLink: `${window.VUE_APP_MS_URL}scan/scans?page=1`,
      prevOptions: null,
      exportLoading: false
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  created() {
    this.searchDatatable = _.debounce(this.searchDatatable, 500);
  },
  mounted() {
    this.getDataFromApi();
    this.wsScanData();
  },
  methods: {
    ...mapActions(['getModel', 'deleteScan', 'exportScanReport']),

    searchDatatable() {
      this.getDataFromApi('search')
    },
    async exportScanData() {
      this.exportLoading = true;
      const response = await this.exportScanReport({ endPoint: 'export-scan-report', params: this.filterData});
      this.exportLoading = false;
      const link = document.createElement("a");
      link.href = response.url;
      link.click();
      window.URL.revokeObjectURL(link.href);
    },
    getDataFromApi(type) {
      this.loading = true;
      this.getDTDetails(type).then(data => {
        this.tableItems = data.items;
        this.totalItems = data.total;
        this.loading = false;
      }).catch(err => {
        this.tableItems = [];
        this.totalItems = 0;
        this.loading = false;
      });
    },
    getDTDetails(type) {
      return new Promise((resolve, reject) => {
        const {sortBy, page, itemsPerPage} = this.options
        const sortKey = sortBy.length ? sortBy[0].key : null;
        const sortOrder = sortBy.length ? sortBy[0].order : null;

        const endPoint = new URL(this.pageLink);
        endPoint.searchParams.set("per_page", itemsPerPage);
        endPoint.searchParams.set("page", page);
        if (type) endPoint.searchParams.set("page", 1);
        if(this.filterData) {
          Object.keys(this.filterData).map(key => this.filterData[key] && endPoint.searchParams.set(key, this.filterData[key]));
        }
        if (this.search !== '') {
          endPoint.searchParams.set("q", this.search);
        }
        this.getAPIdata(endPoint, this.options, type).then(resp => {
          let {items, total} = resp;
          if (sortKey && sortOrder) {
            items = items.sort((a, b) => {
              const sortA = a[sortKey];
              const sortB = b[sortKey];

              if (sortOrder === 'desc') {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            });
          }
          this.prevOptions = this.options;
          resolve({
            items,
            total,
          });

        }).catch(err => reject(err));
      });
    },
    getAPIdata(endPoint, options, type) {
      const {page, itemsPerPage} = options;
      return new Promise((resolve, reject) => {
        if (!type && this.prevOptions && this.prevOptions.page == page && this.prevOptions.itemsPerPage == itemsPerPage) {
          resolve({items: this.tableItems, total: this.totalItems});
          return true;
        }
        this.getModel(endPoint.toString()).then(resp => {
          resolve(resp);
        }).catch(err => reject(err));
      });
    },
    searchFilter(data, type) {
      if (type) {
        if (this.filterData && this.filterData[data]) this.filterData[data] = null;
        return;
      }
      this.filterData = data;
      this.getDataFromApi('filter')
    },
    deleteScanItem(scanId) {
      this.deleteScan(scanId).then(() => this.getDataFromApi('delete'));
    },
    wsBind(scan, event) {
      const itemIndex = this.tableItems.findIndex(item => item.id === scan.id);
      if (event && itemIndex != -1) {
        this.tableItems.splice(itemIndex, 1, scan);
      }
      if (!event) this.tableItems.splice(0, 0, scan);
    },
    wsScanData() {
      Echo.channel('scan').listen('.created', (scan) => this.wsBind(scan));
      Echo.channel('scan').listen('.updated', (scan) => this.wsBind(scan, 'update'));
    }
  },
  beforeUnmount() {
      Echo.leaveChannel('scan');
  }
};
</script>
